.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 96px 0px;
  gap: 64px;

  width: 1440px;
  height: 982px;

  /* Conscious Teal/25 */

  background: #F6FEFC;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 32px;

  width: 1280px;
  height: 218px;
}

.lowerContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 32px;
  gap: 64px;

  width: 1280px;
  height: 508px;
}

.star {
  position: absolute;
  width: 455px;
  height: 455px;
  right: 0;
  top: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 768px;
  height: 218px;
}

.contentBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 768px;
  height: 218px;
}

.headingAndSubheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 768px;
  height: 168px;
}

.subheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Subheading */


  width: 768px;
  height: 24px;

  /* Text md/Semibold */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Gray/700 */

  color: #344054;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Heading */


  text-align: left;
  width: 768px;
  height: 132px;

  /* Display md/Bold */

  font-family: 'Darker Grotesque';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* or 122% */

  letter-spacing: -0.02em;

  /* Gray/900 */

  color: #101828;
}

.supportingText {
  width: 768px;
  height: 30px;

  /* Text xl/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */


  /* Gray/700 */

  color: #344054;
}

.formContainer {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px;
  gap: 64px;

  width: 576px;
  height: 508px;

  /* Conscious Teal/25 */

  background: #F6FEFC;
  /* Conscious Teal/500 */

  border: 1px solid #15B79E;
  border-radius: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 512px;
  height: 428px;
}

.formFields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 512px;
  height: 348px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 512px;
  height: 70px;
}

.wideRow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 512px;
  height: 70px;
}

.nameInput {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 240px;
  height: 70px;
}

.inputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 240px;
  height: 70px;
}

.wideInputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 100%;
  height: 70px;
}

.largestInputWithLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  width: 512px;
  height: 160px;
}

.largestInput {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 14px;
  gap: 8px;

  width: 512px;
  height: 134px;

  /* Base/White */

  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.wideInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: inherit;
  height: 44px;

  /* Base/White */

  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.label {
  width: 71px;
  height: 20px;

  /* Text sm/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
  gap: 8px;

  width: 240px;
  height: 44px;

  /* Base/White */

  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;

  width: 576px;
  height: 396px;
}

.contactText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 576px;
  height: 174px;
}

.emailContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 576px;
  height: 174px;
}

.email .phone {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 576px;
  height: 174px;
}

.icon {
  display:flex;
  flex-direction:flex-start;
  gap:20;
  width: 48px;
  height: 48px;

  /* Conscious Indigo/100 */

  background: #E0EAFF;
  /* Conscious Indigo/50 */

  border: 8px solid #EEF4FF;
  border-radius: 28px;
}

.contactHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 576px;
  height: 30px;

  /* Text xl/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */


  /* Gray/900 */

  color: #101828;
}

.contactSubheader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 576px;
  height: 24px;

  /* Text md/Normal */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Conscious Indigo/700 */

  color: #3538CD;
}

.contactButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 139px;
  height: 24px;
}

.contactButtonText {
  width: 139px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  /* Gray/900 */
  white-space: nowrap;
  color: #101828;
}

.textSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 576px;
  height: 62px;
}

.phoneContainer {
  display: none;
  /* display: flex; */
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 576px;
  height: 174px;
}

.submitButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  cursor:pointer;


  width: 512px;
  height: 48px;

  /* Conscious Teal/800 */

  background: #125D56;
  /* Conscious Teal/500 */

  border: 1px solid #15B79E;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.submitButtonText {

  width: 113px;
  height: 24px;

  /* Text md/Medium */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Base/White */

  color: #FFFFFF;
}
